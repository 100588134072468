import { Ref, ref } from 'vue';
import { $loading } from '@/api/index';
import { setDateFormat } from '@/utils/dateFormatter';
import { toast } from '@/utils/toast';

export interface Crcs {
  flag_name: string;
  sa_clntcode: string;
  clntname: string;
  sicdcode: string;
  sicdname: string;
  dplace_code: string;
  dplace_name: string;
  itnm: string;
  c_cost: number;
  sale_qunt: number;
  slam: number;
  vtam: number;
  hkam: number;
}

export function UseCrcs() {

  const crcss: Ref<Crcs[]> = ref([]);
  //팀용
  const year = ref(setDateFormat(new Date(), 'YYYY'));
  const month = ref(setDateFormat(new Date(), 'MM'));

  const isConnected = ref(true);

  async function fetchDatas(pflag: string, pcFrdt: string, pcTodt: string, pcSale: string) {
    await $loading(`/crcs?p_flag=${pflag}&pc_frdt=${pcFrdt}&pc_todt=${pcTodt}&pc_sale=${pcSale}`, 'POST').then(
      (res: any) => {
        if (res.data == ''){
          crcss.value = [];
          toast('데이터가 존재하지 않습니다.'); 
        } else {
          crcss.value = null;
          crcss.value = res.data;
        }
      }
    );
  }      

  return {
    isConnected,
    fetchDatas,
    year,
    month,
    crcss,
  };
}
